/* eslint-disable jsx-a11y/heading-has-content */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Swal from 'sweetalert2';
import API_ASISTENCIA from '../../../componentes/config/apisAsistencia_Variables';
//Función que Trae la fecha actual y la transforma en formato ddmmaaaa 
const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
const formatFechaReporte = (dateStr) => {
  const [year, month, day] = dateStr.split('-');
  return `${day}${month}${year}`;
};
function MisRegistros({ showModalTabla, onClose, documento }) {
  //Declaración de constantes y sus estados
  const [asistencias, setAsistencias] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [tipofiltro, setTipoFiltro] = useState("HOYR")
  const [loadingFiltro, setLoadingFiltro] = useState(false)
  const [mes, setMes] = useState("30062024")
  //Estado de fecha , trae la fecha de hoy como dato inicial y lo transforma con la función formatDate
  const [fecha, setFecha] = useState(formatDate(new Date()))
  const [modalVisible, setModalVisible] = useState(false);
  const [descripcionModal, setDescripcionModal] = useState('');
  const [showModalReporte, setShowModalReporte] = useState(false);
  const [tipoReporte, setTipoReporte] = useState('R');
  const [fechaReporte, setFechaReporte] = useState('');
  const [fechaHasta, setFechaHasta] = useState('')
  const [loadingReporte, setLoadingReporte] = useState(false)
  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      const formattedDate = formatFechaReporte(selectedDate);
      setFechaReporte(formattedDate);
    }
  };
    const handleDateHastaChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      const formattedDate = formatFechaReporte(selectedDate);
      setFechaHasta(formattedDate);
    }
  };
  const handleTipoReporteChange = (tipo) => {
    setTipoReporte(tipo);
  };
  const PreviewReporte = async () => {
    setLoadingReporte(true);
    try {
      const response = await axios.get(
        `https://api01.cloudlab.net.pe/${tipoReporte === "R" ? `pi805_repor_listreg_res?fecha_deseada=` : `pi806_repor_listreg_ran?fecha_desde=`}${fechaReporte}${tipoReporte === "R" ? `&dni_responsable=${documento}` : `&fecha_hasta=${fechaHasta}&dni_responsable=${documento}`}`,
        {
          responseType: "arraybuffer", // Usamos 'arraybuffer' para la respuesta
        }
      );
  
      if (response.status === 200 && response.data) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
        setLoadingReporte(false);
      } else {
        throw new Error("Datos no válidos en la respuesta");
      }
    } catch (error) {
      console.error("Error al previsualizar el archivo:", error);
      Swal.fire({
        icon: "error",
        title: "Error al previsualizar el archivo",
        text: `No se pudo previsualizar el archivo (pi805_repor_li): ${error.message}`,
      }).then(() => {
        setLoadingReporte(false);
      });
    }
    
  };
  //Función que Cambia la fecha en el input de buscar por fecha
  const handleChangefecha = (event) => {
    const date = event.target.value;
    setFecha(date);
  };
  //Función que regresa la fecha al dia anterión (para botón de buscar por fecha)
  const handlePrevDay = () => {
    try {
      const day = Number(fecha.substring(0, 2));
      const month = Number(fecha.substring(3, 5)) - 1;
      const year = Number(fecha.substring(6, 10));
      const currentDate = new Date(year, month, day);
      currentDate.setDate(currentDate.getDate() - 1);
      const formattedDate = currentDate.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      setFecha(formattedDate);
    } catch (error) {
      console.error('Error en handlePrevDay:', error);
    }
  };
  //Función que regresa la fecha al dia siguiente (para botón de buscar por fecha)
  const handleNextDay = () => {
    try {
      const day = Number(fecha.substring(0, 2));
      const month = Number(fecha.substring(3, 5)) - 1;
      const year = Number(fecha.substring(6, 10));
      const currentDate = new Date(year, month, day);
      currentDate.setDate(currentDate.getDate() + 1);
      const formattedDate = currentDate.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      setFecha(formattedDate);
    } catch (error) {
      console.error('Error en handleNextDay:', error);
    }
  };
  //Función para cambiar el tipo de filtro (HOY , FECHA , MES)
  const handleTipoFiltroChange = (tipo) => {
    setLoadingFiltro(true);
    setTimeout(() => {
      setTipoFiltro(tipo);
      setLoadingFiltro(false)
    }, 120);
  };
  //Función para actualizar el estado de mes (cambiarle el mes)
  const handleChangemes = (event) => {
    const selectedValue = event.target.value;
    setMes(selectedValue);
  };
  //Ejecuta la funcion para obtener los datos del usuario por su dni y actualiza por cada digito de dni que ingrese
  useEffect(() => {
    const obtenerDatosporDNI = async () => {
      setLoadingFiltro(true);
      try {
        //SOLICITUD PARA OBTENER LOS DATOS

        const apiUrl = `https://api01.cloudlab.net.pe/pi0302bh1_asistencia_listget/?VCODBUS=DNI${tipofiltro}&VSERE_P_chDNI=${documento}&VFECHA=${tipofiltro === 'MESR' ? mes : tipofiltro === 'FECHAR' ? fecha.split('/').join('') : '%20'
          }`;
        //ACTUALIZA LOS ESTADOS PARA MOSTRAR LOS DATOS DEL USUARIO         
        const respuesta = await axios.get(apiUrl);
        setAsistencias(respuesta.data);
        setLoadingFiltro(false)
      } catch (error) {
        setShowToast(true)
        setErrorMessage("Error al obtener los datos (pi0302bh1_)")
      }
    };
    obtenerDatosporDNI();
  }, [documento, tipofiltro, mes, fecha]);
  const gettiporegistro = (tiporegistro) => {
    //Switch para darle el nombre de manera visual al tregistro seleccionado
    switch (tiporegistro) {
      case 'Asis' || 'asis':
        return 'Asistencia';
      case 'Inasis':
        return 'Inasistencia';
      case 'Acti':
        return 'Actividad';
      case 'Rad':
        return 'Radio';
      case 'Mot':
        return 'Moto';
      case 'Camio':
        return 'Camioneta';
      case 'Bic':
        return 'Bicicleta';
      case 'SSD':
        return 'Sueldo Sin Deposito';
      case 'SUP':
        return 'Sueldo Pagado';
      case 'PF':
        return 'Puesto Tactico';
      case 'PT':
        return 'Puesto Tactico';
      case 'Onom':
        return 'Onomástico';
      case 'PermP':
        return 'Permiso Personal';
      case 'PermS':
        return 'Permiso por Salud';
      case 'Vac':
        return 'Vacaciones';
      case 'De':
        return 'Descanso';
      default:
        return '';
    }
  };
  //Función para el datatable , destruye el datatable al cerrar el modal para así no haya falla al abrir uno nuevo
  useEffect(() => {
    if (!loadingFiltro) {
      const table = $('#tablaregistrosdni').DataTable();
      if ($.fn.DataTable.isDataTable(table)) {
        table.destroy();
      }
      $.extend(true, $.fn.dataTable.defaults, {
        language: {
          "sProcessing": "Procesando...",
          "sLengthMenu": "Mostrar _MENU_ registros",
          "sZeroRecords": "No se encontraron resultados",
          "sEmptyTable": "Ningún dato disponible en esta tabla",
          "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
          "sInfoPostFix": "",
          "sSearch": "Buscar:",
          "sUrl": "",
          "sInfoThousands": ",",
          "sLoadingRecords": "Cargando...",
          "oPaginate": {
            "sFirst": "Primero",
            "sLast": "Último",
            "sNext": "Siguiente",
            "sPrevious": "Anterior"
          },
          "oAria": {
            "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "buttons": {
            "copy": "Copiar",
            "colvis": "Visibilidad"
          }
        }
      });
      $('#tablaregistrosdni').DataTable();
    }
  }, [loadingFiltro, showModalTabla]);
  //Función que Abre el modal con la descripción del registro
  const handleAbrirModal = (descripcion) => {
    setModalVisible(true);
    setDescripcionModal(descripcion);
  };
  //Obtiene la fecha y le da formato dd/mm/aaaa para mostrarse de manera visual
  const formatofecha = (dateStr) => {
    const day = dateStr.substring(0, 2);
    const month = dateStr.substring(2, 4);
    const year = dateStr.substring(4, 8);
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    if (showModalReporte) {
      onClose();
    }
  }, [showModalReporte, onClose]);
  return (
    <>
    <Modal show={showModalTabla} onHide={onClose} dialogClassName="modal-xl" centered className='funcionmodal'>
      <Modal.Header closeButton className="bg-primary text-center">
        <Modal.Title style={{ "color": "#fff", "width": "100%" }}>Bitácora de mis registros
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tabladedatos">
        {/*<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <img
              src={API_ASISTENCIA['mostrarfotodeusuario'](documento)}
              alt="Foto de Perfil"
              style={{
                maxWidth: "60px",
                maxHeight: "60px",
                marginLeft: "45px",
                borderRadius: "10px",
              }}
              onError={(e) => {
                e.target.src = 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.webp';
              }}
            />
          </div>
         <div>
            <h3 className="horaactual" style={{ color: 'black' }}>DNI: {documento}</h3>
            <h3 className="datos-usuario" style={{ color: 'black' }}>{nombres} {apellidoPaterno} {apellidoMaterno}</h3><br />
            </div>
        </div>
        <hr />*/}
        {showToast && (
          <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
            <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header bg-primary text-white">
                <strong className="me-auto">Mensaje del Sistema</strong>
                <small>Justo Ahora</small>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowToast(false)} // Actualiza el estado para ocultar el toast
                  aria-label="Close"
                ></button>
              </div>
              <div className="toast-body">
                {errorMessage}
              </div>
            </div>
          </div>
        )}
        <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ padding: '10px', width: '100%' }}>
          <input
            type="radio"
            className="btn-check funcionmodal"
            name="options"
            id="option1"
            autoComplete="off"
            checked={tipofiltro === 'HOYR'}
            onChange={() => handleTipoFiltroChange('HOYR')}
          />
          <label className={`btn ${tipofiltro === 'HOYR' ? 'btn-success' : 'btn-secondary'} funcionmodal`} htmlFor="option1">
            Registros de Hoy
          </label>
          <input
            type="radio"
            className="btn-check funcionmodal"
            name="options"
            id="option2"
            autoComplete="off"
            checked={tipofiltro === 'FECHAR'}
            onChange={() => handleTipoFiltroChange('FECHAR')}
          />
          <label className={`btn ${tipofiltro === 'FECHAR' ? 'btn-info' : 'btn-secondary'} funcionmodal`} htmlFor="option2">
            Filtrar por Fecha
          </label>
          <input
            type="radio"
            className="btn-check "
            name="options"
            id="option3"
            autoComplete="off"
            checked={tipofiltro === 'MESR'}
            onChange={() => handleTipoFiltroChange('MESR')}
          />
          <label className={`btn ${tipofiltro === 'MESR' ? 'btn-warning' : 'btn-secondary'} funcionmodal`} htmlFor="option3">
            Filtrar por Mes
          </label>
        </div>
        {loadingFiltro ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {tipofiltro === "MESR" ?
              <>
                <div className="form-group funcionmodal">
                  <label htmlFor="">Escoge un Mes:</label>
                  <select
                    className="form-select funcionmodal"
                    name='mes'
                    value={mes}
                    onChange={handleChangemes}>
                    <option value="31012024">Enero</option>
                    <option value="28022024">Febrero</option>
                    <option value="31032024">Marzo</option>
                    <option value="30042024">Abril</option>
                    <option value="31052024">Mayo</option>
                    <option value="30062024">Junio</option>
                    <option value="31072024">Julio</option>
                    <option value="31082024">Agosto</option>
                    <option value="30092024">Septiembre</option>
                    <option value="31102024">Octubre</option>
                    <option value="30112024">Noviembre</option>
                    <option value="31122024">Diciembre</option>
                  </select>
                </div>
                <br />
              </>
              : tipofiltro === "FECHAR" ?
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <button style={{ border: 'none', background: 'none', cursor: 'pointer' }} onClick={handlePrevDay}><i class="fa-solid fa-circle-arrow-left"></i></button>
                    <input
                      className="form-control funcionmodal"
                      type="text"
                      value={fecha}
                      style={{
                        width: '60%',
                        margin: '0 20px',
                        border: '2px solid black',
                        textAlign: 'center'
                      }}
                      onChange={handleChangefecha}
                    />
                    <button style={{ border: 'none', background: 'none', cursor: 'pointer' }} onClick={handleNextDay}><i class="fa-solid fa-circle-arrow-right"></i></button>
                  </div>
                  <br />
                </>
                :
                <></>
            }
            <div className="table-responsive-xl overflow-auto text-center">
              <table
                id="tablaregistrosdni"
                className="table table-striped">
                <thead>
                  <tr>
                    <th className='text-center'>Foto</th>
                    <th className='text-center'>Fecha</th>
                    <th className='text-center'>Hora</th>
                    <th className='text-center'>Tipo Registro</th>
                    <th className='text-center'>Codigo de Recurso</th>
                    <th className='text-center'>Tipo</th>
                    <th className='text-center'>Registrado</th>
                    <th className='text-center'>Descripcion</th>
                  </tr>
                </thead>
                <tbody>
                  {asistencias.map((asistencia, index) => (
                    <tr key={index}>
                      <td><button
                        class="btn btn-success btn-sm m-1"
                      ><i class="fa-solid fa-camera-retro"></i></button>
                      </td>
                      <td>{formatofecha(asistencia.ASIS_chFEC)}</td>
                      <td>{asistencia.ASIS_chHOR}</td>
                      <td>{gettiporegistro(asistencia.ASIS_chTIPREG)}</td>
                      <td>{!asistencia.Cata_CH_Codcatarec ? "No registra" : asistencia.Cata_CH_Codcatarec}</td>
                      <td style={{ color: asistencia.ASIS_chEntSal === 'E' || asistencia.ASIS_chEntSal === 'R' ? 'rgb(21, 137, 21)' : asistencia.ASIS_chEntSal === 'P' || asistencia.ASIS_chEntSal === 'O' ? '#2d2f30' : 'rgb(242, 61, 61)' }}>
                        {asistencia.ASIS_chEntSal === 'E' ? 'Entrada' : asistencia.ASIS_chEntSal === 'R' ? 'Recurso' : asistencia.ASIS_chEntSal === 'P' ? 'Pago' : asistencia.ASIS_chEntSal === 'O' ? 'Otro' : 'Salida'}
                      </td>
                      <td>{asistencia.SERE_P_chDNI === null ? "No registra" : asistencia.SERE_P_chDNI}</td>
                      <td>
                        {asistencia.ASIS_chDESACT === "" ? "--" : (
                          <button onClick={() => handleAbrirModal(asistencia.ASIS_chDESACT)}
                            class="btn btn-warning btn-sm m-1"
                          ><i class="fa-solid fa-eye"></i></button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {modalVisible && (
                <Modal show={modalVisible} onHide={() => setModalVisible(false)} centered>
                  <Modal.Header closeButton>
                    <div style={{ maxWidth: '90%' }}>
                      <p style={{ overflowWrap: 'break-word' }}>{descripcionModal}</p>
                    </div>
                  </Modal.Header>
                </Modal>
              )}
            </div>
          </>
        )}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={() => setShowModalReporte(true)}>
          Generar Reporte
        </Button>
        <Button variant="danger" onClick={onClose} >
          Cerrar
        </Button>
      </Modal.Footer>

    </Modal >

<Modal show={showModalReporte} onHide={() => { setShowModalReporte(false) }} centered className='funcionmodal'>
        <Modal.Header closeButton className="bg-warning text-center">
          <Modal.Title style={{ "color": "#000", "width": "100%" }}>Reporte de mis registros
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tabladedatos">
          <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ padding: '10px', width: '100%' }}>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option4"
              autoComplete="off"
              checked={tipoReporte === 'R'}
              onChange={() => handleTipoReporteChange('R')}
            />
            <label className={`btn ${tipoReporte === 'R' ? 'btn-danger' : 'btn-secondary'} funcionmodal`} htmlFor="option4">
              Mis Registros
            </label>

            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option5"
              autoComplete="off"
              checked={tipoReporte === 'RF'}
              onChange={() => handleTipoReporteChange('RF')}
            />
            <label className={`btn ${tipoReporte === 'RF' ? 'btn-dark' : 'btn-secondary'} funcionmodal`} htmlFor="option5">
              Rango de Fechas
            </label>
          </div>
          <hr />

          {tipoReporte === "R" ?
            < div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12" style={{ display: 'flex', flexDirection: 'column', width: '100%', color: 'black' }}>
                <label htmlFor="" style={{ margin: '5px 0', textAlign: 'left', fontSize: '12px', fontWeight: 'bold' }}>Elija la Fecha:</label>
                <input
                  className="form-control"
                  type="date"
                  style={{ margin: '5px 0', textAlign: 'center', fontSize: '12px', color: 'black', fontWeight: 'bold',borderRadius:'2px' }}
                  onChange={handleDateChange} />
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12" style={{ display: 'flex', flexDirection: 'column', width: '100%', color: 'black' }}>
                <label htmlFor="" style={{ margin: '5px 0', textAlign: 'left', fontSize: '12px', fontWeight: 'bold' }}>Documento:</label>
                <input
                  className="form-control"
                  type="text"
                  value={documento}
                  style={{ margin: '5px 0', textAlign: 'center', fontSize: '12px', width: '100%', color: 'black'}}
                  disabled />
              </div>
            </div>
            :
            < div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12" style={{ display: 'flex', flexDirection: 'column', width: '100%', color: 'black' }}>
                <label htmlFor="" style={{ margin: '5px 0', textAlign: 'left', fontSize: '12px', fontWeight: 'bold' }}>Elija la Fecha desde:</label>
                <input
                  className="form-control"
                  type="date"
                  style={{ margin: '5px 0', textAlign: 'center', fontSize: '12px', color: 'black', fontWeight: 'bold',borderRadius:'2px' }}
                  onChange={handleDateChange} />
              </div>
               <div className="col-xl-6 col-md-6 col-sm-12" style={{ display: 'flex', flexDirection: 'column', width: '100%', color: 'black' }}>
                <label htmlFor="" style={{ margin: '5px 0', textAlign: 'left', fontSize: '12px', fontWeight: 'bold' }}>Elija la Fecha hasta:</label>
                <input
                  className="form-control"
                  type="date"
                  style={{ margin: '5px 0', textAlign: 'center', fontSize: '12px', color: 'black', fontWeight: 'bold',borderRadius:'2px' }}
                  onChange={handleDateHastaChange} />
              </div>

            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={PreviewReporte}>
            {loadingReporte ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              :
              <>Generar PDF</>
            }
          </Button>
          <Button variant="danger" onClick={() => { setShowModalReporte(false) }} >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
</>
  )
}
export default MisRegistros