/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "../../CSS/App.css";
import API_ASISTENCIA from '../../../componentes/config/apisAsistencia_Variables';
const fecha = new Date();
const dia = fecha.getDate();
const mes = fecha.getMonth() + 1;
const año = fecha.getFullYear().toString().slice(-2);
function QrCamera(props) {
  //Declaración de const
  //Declarando los props enviados desde el componente padre -- Registroasistencia
  const {handleResetIdAsistencia} = props
  const { documentousuario } = props;
  const { documentoresponsable } = props;
  const { dnivalido } = props;
  const {idAsistencia} = props
  const [datos, setDatos] = useState(null);
  //Const que maneja el estado para abrir y cerrar el modal de Foto
  const [showModalPhoto, setShowModalPhoto] = useState(false);
  const [address, setAddress] = useState("");
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [cameraOn, setCameraOn] = useState(true);
  const [photoAceptada, setPhotoAceptada] = useState(true);
  const [cameraError, setCameraError] = useState(false);

  const videoRef = useRef();
  let streamRef = useRef(null);
  //Funcion para insertar la marca de agua a la foto
  const addWatermark = (imageSrc, dateTime) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = imageSrc;
  
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      ctx.font = "bold 15px Arial";
      ctx.textAlign = "left";
      ctx.textBaseline = "top";
  
      const margin = 10;
      const lineHeight = 30;
  
      const textDate = dateTime;
      const textUser = `${datos?.SERE_chNOM ?? ''} ${datos?.SERE_chAPEPAT ?? ''} ${datos?.SERE_chAPEMAT ?? ''}`;
      const textAddress = address;
  
      const x = margin;
      let y = margin;
  
      const drawTextWithBorder = (text, x, y) => {
        ctx.fillStyle = "#000"; // Color del borde
        ctx.lineWidth = 6; // Ancho del borde
        ctx.strokeText(text, x, y); // Dibujar borde
        ctx.fillStyle = "#fff"; // Color del texto
        ctx.fillText(text, x, y); // Dibujar texto
      };
  
      // Dibujar cada línea de texto con borde
      drawTextWithBorder(textDate, x, y);
      y += lineHeight;
      drawTextWithBorder(textUser, x, y);
      y += lineHeight;
      drawTextWithBorder(textAddress, x, y);
  
      const imageWithWatermarkURL = canvas.toDataURL("image/jpeg");
      setPhotoURL(imageWithWatermarkURL);
    };
  };
  //Funcion para tomar la foto
  const takePhoto = async () => {
    try {
      const video = videoRef.current;
      if (video) {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        const photoURL = canvas.toDataURL("image/jpeg");
        const now = new Date();
        const dateTime = now.toLocaleString();
        addWatermark(photoURL, dateTime);
        setShowModalPhoto(true);
      } else {
        console.error("Video ref is not initialized.");
      }
    } catch (error) {
      console.error("Error al capturar la foto:", error);
    }
  };
  //Función para recargar la camara
  const handleToggleCamera = () => {

    setCameraOn((prevState) => !prevState);
  };
  //Funcion para limpiar la foto
  const handlelimpiarfoto = async () => {
    //Valida que haya una foto guardada
    if (!photoURL) {
      Swal.fire({
        icon: "error",
        title: "Error al limpiar",
        text: "Aún no hay una foto para limpiar",
      });
      return;
    }
    //Limpia la foto guardada y recarga la camara
    setPhotoURL("");
    setPhotoAceptada("");
    handleToggleCamera();
  };
  //Funcion para cambiar la camara , registra las camaras que contiene el dispositivo y las lista , luego cambia una a otra
  const handleCambiarCamara = async () => {
    if (!idAsistencia) {
      Swal.fire({
        icon: "error",
        title: "Error al Tomar Foto",
        text: "Primero debe registrar su asistencia o actividad.",
      });
      return;
    }
    if (!longitud && !latitud) {
      Swal.fire({
        icon: "error",
        title: "Error al subir",
        text: "Active su ubicación y recargue la página",
      });
      return;
    }
    try {
      const videoStream = videoRef.current.srcObject;
      const tracks = videoStream.getTracks();
      tracks.forEach((track) => track.stop());
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      let nextCameraIndex = 0;
      videoDevices.forEach((device, index) => {
        if (device.deviceId === tracks[0].getSettings().deviceId) {
          nextCameraIndex = (index + 1) % videoDevices.length;
        }
      });
      const nextCameraStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: videoDevices[nextCameraIndex].deviceId },
        },
      });
      videoRef.current.srcObject = nextCameraStream;
    } catch (error) {
      console.error("Error al cambiar la cámara:", error);
    }
  };
  //funcion que enciende la cámara
  useEffect(() => {
    const enableCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoRef.current.srcObject = stream;
        setCameraError(false)
      } catch (error) {
        console.error("Error al acceder a la cámara:", error);
        setCameraOn(true);
        setCameraError(true)
        Swal.fire({
          icon: "error",
          title: "Error en la cámara",
          text: "Debe activar su cámara.",
        });
      }
    };
    if (videoRef.current) {
      enableCamera();
    }
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, [videoRef, cameraOn,cameraError]);

  //Renderiza la funcion de ubicación para que se ejecute al iniciar la pagina
  useEffect(() => {
    //Obtener la ubicaciòn de googlemaps
    const fetchAddress = async (latitude,longitude ) => {
      const apiKey = "a1100a44b44349b6a3bb16186a5e5a3d";
      const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const formattedAddress = data.results[0].formatted;
          setAddress(formattedAddress);
        } else {
          setAddress("Address not found");
        }
      } catch (error) {
        console.error("Error fetching address:", error);
        setAddress("Error fetching address");
      }
    };
    //Función para obtener la ubicación
    const obtenerUbicacion = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatitud(latitude);
            setLongitud(longitude);
            fetchAddress(latitude,longitude)
          },
          (error) => {
            console.error("Error al obtener la ubicación:", error);
          }
        );
      } else {
        console.error(
          "La geolocalización no es compatible con este navegador."
        );
      }
    };
    fetchAddress()
    obtenerUbicacion();
    //Obtener los datos del sereno consultado
    const obtenerDatos = async () => {
      try {
        const respuesta = await axios.get(API_ASISTENCIA['serenosPorDNI'](documentousuario));
        //Enviamos los datos obtenidos en la respuesta, enviamos a la const Datos(nos almacena el array con los datos)
        setDatos(respuesta.data);
    
      } catch (error) {

      }
    };
    obtenerDatos()

  }, [documentousuario]);
  //Función que inicia la camara al darle al boton de cámara
  useEffect(() => {
    if (!cameraOn) {
      reloadCamera();
    }
  }, [cameraOn]);
  //Función para cerrar el modal de foto
  const handleClosePhoto = () => {
    setShowModalPhoto(false);
    stopVideoStream();
  };
  //Función del boton aceptar , guarda la foto y cambia el estado de foto aceptada que luego se utiliza para mostrar la foto en pantalla al darle aceptar
  const handleAceptPhoto = () => {
    setShowModalPhoto(false);
    stopVideoStream();
    setPhotoAceptada(true);
  };
  //Funcion para el boton de tomar foto , abre el modal y toma la foto
  const handleTomarPhoto = () => {
    if (!idAsistencia) {
      Swal.fire({
        icon: "error",
        title: "Error al Tomar Foto",
        text: "Primero debe registrar su asistencia o actividad.",
      });
      return;
    }
    if (!longitud && !latitud) {
      Swal.fire({
        icon: "error",
        title: "Error al subir",
        text: "Active su ubicación y recargue la página",
      });
      return;
    }
    if (cameraError) {
      Swal.fire({
        icon: "error",
        title: "Error en la cámara",
        text: "Debe activar su cámara.",
      });
      return;
    }
    setShowModalPhoto(true);
    takePhoto();
  };
  //Funcion para detener la camara al abrir el modal de foto
  const stopVideoStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };
  const reloadCamera = () => {
    stopVideoStream();
    setCameraOn(true);
    setPhotoAceptada(false);
    setPhotoURL("");
  };
  //Funcion para subir la foto con la solicitud POST
  const handleUpload = async (event) => {
    event.preventDefault();
    try {
      //Validacion por si no hay una foto guardada
      if (!photoAceptada || !photoURL) {
        Swal.fire({
          icon: "error",
          title: "Error al subir",
          text: "Debe capturar una foto.",
        });
        return;
      }
      //Validacion por si no hay un documento insertado
      if (!documentousuario) {
        Swal.fire({
          icon: "error",
          title: "Error al subir",
          text: "Debe ingresar un dni.",
        });
        return;
      }
      //Validacion por si no hay un dni valido para la foto
      if (dnivalido === false) {
        Swal.fire({
          icon: "error",
          title: "Error al subir",
          text: "Debe ingresar su asistencia.",
        });
        return;
      }
      //Validacion por si no tiene activa su ubicacion
      if (!longitud && !latitud) {
        Swal.fire({
          icon: "error",
          title: "Error al subir",
          text: "Debe Activar su ubicación.",
        });
        return;
      }
      if (!idAsistencia) {
        Swal.fire({
          icon: "error",
          title: "Error al subir",
          text: "Debe Registrar una asistencia para tomar la foto.",
        });
        return;
      }
      const formData = new FormData();
      const blob = await fetch(photoURL).then((res) => res.blob());
      const fechahoy = `${dia < 10 ? '0' + dia : dia}${mes < 10 ? '0' + mes : mes}${año}`;
      formData.append("file1", blob, `A${fechahoy}-${documentousuario}-${documentoresponsable}.jpg`); // Proporciona el nombre del archivo manualmente
      //envia la foto
      const response = await axios.post(
        API_ASISTENCIA['enviarfotoevidencia'](documentousuario,documentoresponsable,latitud,longitud,idAsistencia),
        formData
      );
      console.log(response.data);
      if (response.status >= 200 && response.status < 300) {
        Swal.fire({
          icon: "success",
          title: "Subida Exitosa",
          text: "La imagen se subió correctamente.",
        }).then(() => {
          setPhotoURL("");
          setPhotoAceptada(false)
          handleResetIdAsistencia()
        });
      } else {
        console.log("Fallo en la subida del archivo");
        Swal.fire({
          icon: "error",
          title: "Error al registrar imagen",
          text: "No se pudo subir la imagen",
        });
      }
    } catch (error) {
      console.error("Error al subir el archivo:", error);
    }
  };
  return (
    <div style={{width:'100%'}}>
 
        <div
          className="camaraconcainer"
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: "550px",
              maxHeight: "100%",
              margin: "10px",
              display: "flex",
            justifyContent: "center",
            alignItems: "center",
            }}
          >
            {cameraOn &&
              (photoAceptada && photoURL ? (
                <img
                  src={photoURL}
                  alt="Captured"
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                />
              ) : (
                <video
                  ref={videoRef}
                  autoPlay={true}
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                />
              ))}
          </div>
        </div>
        
        <div
          className="divbotonesbajocamara"
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            flexDirection:'column',
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
            <div  className="divbotonesbajocamara"
            style={{
              display:'flex',
              width:'60%',
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",

            }}>
            {photoAceptada && photoURL ? (
                          <button
                          className="btn btn-danger botonlimpiarfoto"
                          onClick={handlelimpiarfoto}
                          Title="Subir foto"
                          style={{
                            width: "100%",
                            borderRadius: "2px",
                          }}
                        >
                          Limpiar Foto{' '}<i class="fa-solid fa-trash-can"></i>
                        </button>
            ) : (
              <div className="botones-Camara" style={{width:'100%'}}>
              <button
                className="btn btn-primary cargarcamara"
                onClick={handleToggleCamera}
                title="Recargar Cámara"
                style={{
                  width: "100%",
                  borderRadius: "2px",
                }}
              >
                Cargar Cámara{' '}<i class="fa-solid fa-camera"></i>
              </button>
              <button
              className="btn btn-warning botoncambiarcamara"
              onClick={handleCambiarCamara}
              title="Cambiar Cámara"
              style={{ display: "none" }}
            >
              Cambiar Cámara {' '}<i class="fa-solid fa-camera-rotate"></i>
            </button>
            </div>
            )}
        
            </div>
            
            <div  className="divbotonesbajocamara"
            style={{
              display:'flex',
              width:'60%',
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",

            }}>
              {photoAceptada && photoURL ? (
                <button
                className="btn btn-success botonsubirfoto"
                onClick={handleUpload}
                Title="Subir foto"
                style={{
                  width: "100%",
                  borderRadius: "2px",
                }}
              >
                Guardar Foto <i class="fa-solid fa-floppy-disk"></i>
              </button>

            ) : (
              <button
              className="btn btn-dark botontomarfoto"
              onClick={handleTomarPhoto}
              Title="Tomar Foto"
              style={{
                width: "100%",
                borderRadius: "2px",
                color: "white",
              }}
            >
              Tomar Foto{' '}<i class="fa-solid fa-photo-film"></i>
            </button>
            )}
            </div>   
          </div>
            
        <Modal
          show={showModalPhoto}
          onHide={handleClosePhoto}
          className="custom-modal"
          centered
        >
          <Modal.Body className="datos">
            {photoURL && (
              <img
                src={photoURL}
                alt="Captured"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              href={photoURL}
              variant="warning"
              download="Captured"
              className="funcionmodal"
            >
              Descargar
            </Button>

            <Button
              variant="success"
              onClick={handleAceptPhoto}
              className="funcionmodal"
            >
              Aceptar Foto
            </Button>
            <Button
              variant="danger"
              onClick={handleClosePhoto}
              className="funcionmodal"
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}
export default QrCamera;
