
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Select from 'react-select';
import API_ASISTENCIA from "../../componentes/config/apisAsistencia_Variables";
import QrCamera from "./Modal/QrCamera";
import MisRegistros from "./Modal/MisRegistros";
import RegistrosUsuario from "./Modal/RegistrosUsuario";
import ModalAsistencia from "./Modal/ModalAsistencia";
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import '../CSS/App.css'
import 'datatables.net';
import 'datatables.net-bs5';
//Mensaje de error 505 para todas las consultas
const error500 = "Error en el servidor"
//Obtener la fecha ,día ,mes y año
const fecha = new Date();
const dia = fecha.getDate();
const mes = fecha.getMonth();
const año = fecha.getFullYear();
function Registroasistencia() {
  //Declaración de Constantes para el manejo de estados y valores
  const [datos, setDatos] = useState(null);
  const [documento, setDocumento] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalTablaMisRegistros, setShowModalTablaMisRegistros] = useState(false);
  const [showModalTablaUsuario, setshowModalTablaUsuario] = useState(false);
  const [showModalBuscador, setShowModalBuscador] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [horaConsulta, setHoraConsulta] = useState("");
  const rol = Cookies.get("rol")
  const nombre = Cookies.get("nombre")
  const apellidopat = Cookies.get("apellidopat")
  const apellidomat = Cookies.get("apellidomat")
  const [perfilesmes, setPerfilesMes] = useState([])
  const codigoderol = Cookies.get("codigorol")
  const codigoderol2 = Cookies.get("codigorol2")
  const codigoderol3 = Cookies.get("codigorol3")
  const horario = Cookies.get("horario")
  const dnilogin = Cookies.get("dnilogin");
  const [codigoUsuario, setCodigoUsuario] = useState("");
  const [rolUsuario, setRolUsuario] = useState("");
  const [horaActual, setHoraActual] = useState("");
  const [funciones, setFunciones] = useState([]);
  const [selectedFuncion, setSelectedFuncion] = useState('');
  const [listTRegistros, setListTRegistros] = useState([])
  const [tregistro, settRegistro] = useState("")
  const [tipoFecha, setTipoFecha] = useState("fechoy")
  const [fechaSelec, setFechaSelect] = useState(null)
  const [horaSelec, setHoraSelec] = useState('08:00')
  const [desact, setDesact] = useState("")
  const [radios, setRadios] = useState([])
  const [motos, setMotos] = useState([])
  const [bicicletas, setBicicletas] = useState([])
  const [camionetas, setCamionetas] = useState([])
  const [serenos, setSerenos] = useState([])
  const [puestosFijos, setPuestosFijos] = useState([])
  const [tipoRegistro, setTipoRegistro] = useState('REB')
  const [selectedRecurso, setSelectedRecurso] = useState('');
  const [selectedRecursoMovil, setSelectedRecursoMovil] = useState('');
  const [selectedRecursoPF, setSelectedRecursoPF] = useState('');
  const [selectedCataCHSecpolicial, setSelectedCataCHSecpolicial] = useState(null);
  const [selectedCataCHJurpolicial, setSelectedCataCHJurpolicial] = useState(null);
  const [rolusuarionorol, setRolUsiarioNoRol] = useState("")
  const [latitud, setLatitud] = useState("");
  const [longitud, setLongitud] = useState("");
  const [dnivalido, setDniValido] = useState(false)
  const [idAsistencia, setIdAsistencia] = useState('')
  const meses =
    ['enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ];
  const fechahoy = `${dia < 10 ? '0' + dia : dia} de ${meses[mes]} del ${año}`;
  const [showToast, setShowToast] = useState(false);

  const handleDocumentoChange = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      setDocumento(inputValue);
    } else {
    }
  }
  const handleClose = () => {
    setShowModal(false);
    setShowModalBuscador(false)
    //Limpiar el mensaje de error
    setErrorMessage("");
    setShowToast(false);
  };
  const handleTipoRegistroChange = (tipo) => {
    setTipoRegistro(tipo);
  };

  const handleTipoFechaChange = (tipo) => {
    setTipoFecha(tipo);
  };
  const handleResetIdAsistencia = () => {
    setIdAsistencia("");
  };
  const handleShow = () => setShowModal(true);
  const handleShowBuscador = () => setShowModalBuscador(true);

  const obtenerDatos = async () => {
    try {
      const respuesta = await axios.get(API_ASISTENCIA['serenosPorDNI'](documento));
      setDatos(respuesta.data);
      setDniValido(true)
      handleShow();
      if (respuesta.status === 200) {
        const respuestarolusuario = await axios.get(API_ASISTENCIA['ConsultarRol'](documento));
        const usuarioData = respuestarolusuario.data[0];
        const codrolusuario = usuarioData ? usuarioData.cata_CH_Cod_rolserv : "";
        const idrolusuario = usuarioData ? usuarioData.cata_IN_IDPK_rolserv : "";
        const horariousuario = usuarioData ? usuarioData.cata_CH_horario_rolserv : "";
        setCodigoUsuario(codrolusuario)
        setRolUsuario(idrolusuario)
        Cookies.set("codrolusuario", codrolusuario);
        Cookies.set("idrolusuario", idrolusuario);
        Cookies.set("horariousuario", horariousuario);
      }
      const dni = respuesta.data.SERE_P_chDNI;
      const horaActual = new Date();
      const horaConsultaFormato = horaActual.toLocaleTimeString();
      setHoraConsulta(horaConsultaFormato);
      Cookies.set("horaConsulta", horaConsultaFormato);
      Cookies.set("dnisereno", dni);
      const respuestarolmes = await axios.get(API_ASISTENCIA['ConsultaRolMes'](dnilogin));
      setPerfilesMes(respuestarolmes.data);
    } catch (error) {
      setDniValido(false)
      setErrorMessage(`${documento === "" ? "Debe ingresar un documento" : "!No se encontró el documento¡"}`);
      setShowToast(true);
    }
  };

  const guardarLocalmente = (datosAsistencia) => {
    const registrosPendientes = JSON.parse(localStorage.getItem('registrosPendientes')) || [];
    registrosPendientes.push(datosAsistencia);
    localStorage.setItem('registrosPendientes', JSON.stringify(registrosPendientes));
  };

  const enviarDatosPendientes = async () => {
    const registrosPendientes = JSON.parse(localStorage.getItem('registrosPendientes')) || [];
    const registrosEnviados = JSON.parse(localStorage.getItem('registrosEnviados')) || [];

    for (const datos of registrosPendientes) {
      try {
        const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'](tipoFecha, fechaSelec, horaSelec), datos);
        console.log('Respuesta de la API:', respuestaPost.data);
        registrosEnviados.push(datos);
        localStorage.setItem('registrosEnviados', JSON.stringify(registrosEnviados));
      } catch (error) {
        console.error('Error al enviar los datos:', error);
      }
    }
    localStorage.removeItem('registrosPendientes');
  };

  const registrarAsistencia = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const horaGuardada = Cookies.get("horaConsulta");
    const idrol = Cookies.get("idrol");
    const codigorol = Cookies.get("codigorol")
    const dniregistrante = Cookies.get("dnilogin");

    const datosAsistencia = {
      sere_p_chdni: dni,
      asis_chhor: horaGuardada,
      asis_chentsal: tipoAsistencia,
      asis_choapp: "appwebasis",
      asis_chfun: selectedFuncion,
      asis_chtipreg: tregistro,
      asis_chdesact: tregistro === "Inasis" || tregistro === "Onom" || tregistro === "PermP" || tregistro === "PermS" || tregistro === "Vac" || tregistro === "SSD" || tregistro === "Acti" || tregistro === "Tar" ? desact : "",
      Cata_CH_Codcatarec: selectedRecurso,
      ASIS_CH_TIPO_BI: 'I',
      cata_CH_Cod_rolserv: !horario ? "" : codigorol,
      cata_IN_IDPK_rolserv: idrol,
      ASIS_IN_idpk_respondoc: dniregistrante,
      cata_CH_IDPK_rolservsr: codigoUsuario ? "" : rolusuarionorol,
      asis_in_lat: latitud,
      asis_in_lon: longitud
    };
    try {
      if (!dni || !horaGuardada) {
        console.error('No hay datos guardados para registrar la asistencia.');
        return;
      }
      if (!longitud && !latitud) {
        Swal.fire({
          icon: "error",
          title: "Active su ubicación",
          text: "Active su ubicación y vuelva a cargar la página.",
        });
        return;
      }
      if (tregistro === "" || ((tregistro === "Rad" || tregistro === "Camio" || tregistro === "Mot" || tregistro === "Bic") && selectedRecurso === "")) {
        Swal.fire({
          icon: 'error',
          title: 'Error al registrar la asistencia',
          text: 'Debe Completar todos los campos',
        });
      } else {
        const result = await Swal.fire({
          icon: 'question',
          title: tipoAsistencia === "E" ? "¿Está seguro de registrar los siguientes datos de entrada?" : "¿Está seguro de registrar los siguientes datos de Salida?",
          html: `
            <h5><strong>${datos.SERE_chNOM} ${datos.SERE_chAPEPAT} ${datos.SERE_chAPEMAT}</strong></h2>
            <p>DNI: <strong>${dni}</strong></p>
            <p>Función:<strong> ${selectedFuncion}</strong></p>
            <p>Tipo de Ingreso: <strong>${gettiporegistro(tregistro)}</strong></p>
            ${tregistro === "Acti" ? `<p>Descripcion: ${desact}</p>` : tregistro === "Rad" ? `<p>Código de Radio: <strong>${selectedRecurso}</strong></p>` : ""}
          `,
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar'
        });
        if (result.isConfirmed) {
          const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'](tipoFecha, fechaSelec, horaSelec), datosAsistencia);
          console.log('Respuesta de la API:', respuestaPost.data);
          Swal.fire({
            icon: tipoAsistencia === "E" ? "success" : "warning",
            title: tipoAsistencia === "E" ? "Registro Exitoso" : tipoAsistencia === "S" ? "Registro Exitoso" : "Registro Exitoso",
            text: tipoAsistencia === "E" ? "¡Registre su foto!" : tipoAsistencia === "S" ? "¡Registre su foto!" : "¡Registre su foto!",
            allowOutsideClick: false
          }).then(() => {
            setShowModal(false);
            setShowToast(true);
            setIdAsistencia(respuestaPost.data.asis_p_inid)
            setErrorMessage(`El registro se realizó correctamente`);
            if (tipoAsistencia === "E") {
              setTimeout(() => {
                setErrorMessage('Ahora debe registrar su foto');
                setShowToast(true);
              }, 2000);
            }
            settRegistro("")
            setDesact("")
            setSelectedFuncion("")
            setSelectedRecurso("")
            setSelectedRecursoMovil("")
            setSelectedRecursoPF("")
            setHoraSelec("")
            setFechaSelect("")
          });
        } else {
          setShowToast(true);
          setErrorMessage(`Operación Cancelada por el usuario`);
        }
      }
    } catch (error) {
      setShowToast(true);
      setErrorMessage(error500, "(pi011e_a)");
      setShowModal(false);
      guardarLocalmente(datosAsistencia);
    }
  };
  //POST MOVIL DEL REGISTRO EN BLOQUE
  const registroRecursoMovil = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const horaGuardada = Cookies.get("horaConsulta");
    const idrol = Cookies.get("idrol");
    const codigorol = Cookies.get("codigorol")
    const dniregistrante = Cookies.get("dnilogin");
    //validación de datos que se enviarán al metodo POST
    const datosAsistencia = {
      sere_p_chdni: dni,
      asis_chhor: horaGuardada,
      asis_chentsal: tipoAsistencia,
      asis_choapp: "appwebasis",
      asis_chfun: selectedFuncion,
      asis_chtipreg: (
        selectedFuncion === "SERENO CONDUCTOR" ? "Camio" :
          selectedFuncion === "SERENO MOTORIZADO" ? "Mot" : ""
      ),
      asis_chdesact: "",
      Cata_CH_Codcatarec: selectedRecursoMovil,
      ASIS_CH_TIPO_BI: 'B',
      asis_CH_Jurpolicial: selectedCataCHJurpolicial,
      asis_CH_Secpolicial: selectedCataCHSecpolicial,
      cata_CH_Cod_rolserv: !horario ? "" : codigorol,
      cata_IN_IDPK_rolserv: idrol,
      ASIS_IN_idpk_respondoc: dniregistrante,
      cata_CH_IDPK_rolservsr: codigoUsuario ? "" : rolusuarionorol,
      asis_in_lat: latitud,
      asis_in_lon: longitud
    };
    try {

      //SOLICITUD POST
      const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'](tipoFecha, fechaSelec, horaSelec), datosAsistencia);
      console.log('Se subieron los datos correctamente:', respuestaPost.data);
      //La consulta es exitosa , se confirma el post.
      return true; // Devuelve true si el registro fue exitoso

    } catch (error) {
      //Si no es exitosa , se muestra un error
      setErrorMessage(error500, "(pi011e_a)");
      setShowToast(true);
      guardarLocalmente(datosAsistencia);
      return false; // Devuelve false si el registro fue exitoso

    }
  };
  const registroRecursoRadio = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const horaGuardada = Cookies.get("horaConsulta");
    const idrol = Cookies.get("idrol");
    const codigorol = Cookies.get("codigorol")
    const dniregistrante = Cookies.get("dnilogin");
    //validación de datos que se enviarán al metodo POST
    const datosAsistencia = {
      sere_p_chdni: dni,
      asis_chhor: horaGuardada,
      asis_chentsal: tipoAsistencia,
      asis_choapp: "appwebasis",
      asis_chfun: selectedFuncion,
      asis_chtipreg: "Rad",
      asis_chdesact: "",
      Cata_CH_Codcatarec: selectedRecurso,
      ASIS_CH_TIPO_BI: 'B',
      asis_CH_Jurpolicial: selectedCataCHJurpolicial,
      asis_CH_Secpolicial: selectedCataCHSecpolicial,
      cata_CH_Cod_rolserv: !horario ? "" : codigorol,
      cata_IN_IDPK_rolserv: idrol,
      ASIS_IN_idpk_respondoc: dniregistrante,
      cata_CH_IDPK_rolservsr: codigoUsuario ? "" : rolusuarionorol,
      asis_in_lat: latitud,
      asis_in_lon: longitud
    };
    try {

      //SOLICITUD POST
      const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'](tipoFecha, fechaSelec, horaSelec), datosAsistencia);
      console.log('Se subieron los datos correctamente:', respuestaPost.data);
      //La consulta es exitosa , se confirma el post.
      return true; // Devuelve true si el registro fue exitoso

    } catch (error) {
      //Si no es exitosa , se muestra un error
      setErrorMessage(error500, "(pi011e_a)");
      setShowToast(true);
      setShowModal(false);
      guardarLocalmente(datosAsistencia);
      return false; // Devuelve false si el registro fue exitoso

    }
  };
  const registroRecursoPuesto = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const horaGuardada = Cookies.get("horaConsulta");
    const idrol = Cookies.get("idrol");
    const codigorol = Cookies.get("codigorol")
    const dniregistrante = Cookies.get("dnilogin");
    //validación de datos que se enviarán al metodo POST
    const datosAsistencia = {
      sere_p_chdni: dni,
      asis_chhor: horaGuardada,
      asis_chentsal: tipoAsistencia,
      asis_choapp: "appwebasis",
      asis_chfun: selectedFuncion,
      asis_chtipreg: "PT",
      asis_chdesact: "",
      Cata_CH_Codcatarec: selectedRecursoPF,
      ASIS_CH_TIPO_BI: 'B',
      asis_CH_Jurpolicial: selectedCataCHJurpolicial,
      asis_CH_Secpolicial: selectedCataCHSecpolicial,
      cata_CH_Cod_rolserv: !horario ? "" : codigorol,
      cata_IN_IDPK_rolserv: idrol,
      ASIS_IN_idpk_respondoc: dniregistrante,
      cata_CH_IDPK_rolservsr: codigoUsuario ? "" : rolusuarionorol,
      asis_in_lat: latitud,
      asis_in_lon: longitud
    };
    try {

      //SOLICITUD POST
      const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'](tipoFecha, fechaSelec, horaSelec), datosAsistencia);
      //La consulta es exitosa , se confirma el post.
      console.log('Se subieron los datos correctamente:', respuestaPost.data);
      return true; // Devuelve true si el registro fue exitoso
    } catch (error) {
      //Si no es exitosa , se muestra un error
      setErrorMessage(error500, "(pi011e_a)");
      setShowToast(true);
      setShowModal(false);
      guardarLocalmente(datosAsistencia);
      return false;

    }
  };
  const registroAsistenciaBloque = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const horaGuardada = Cookies.get("horaConsulta");
    const idrol = Cookies.get("idrol");
    const codigorol = Cookies.get("codigorol")
    const dniregistrante = Cookies.get("dnilogin");
    //validación de datos que se enviarán al metodo POST
    const datosAsistencia = {
      sere_p_chdni: dni,
      asis_chhor: horaGuardada,
      asis_chentsal: tipoAsistencia,
      asis_choapp: "appwebasis",
      asis_chfun: selectedFuncion,
      asis_chtipreg: "Asis",
      asis_chdesact: '',
      Cata_CH_Codcatarec: '',
      ASIS_CH_TIPO_BI: 'B',
      cata_CH_Cod_rolserv: !horario ? "" : codigorol,
      cata_IN_IDPK_rolserv: idrol,
      ASIS_IN_idpk_respondoc: dniregistrante,
      cata_CH_IDPK_rolservsr: codigoUsuario ? "" : rolusuarionorol,
      asis_in_lat: latitud,
      asis_in_lon: longitud
    };
    try {

      //SOLICITUD POST
      const respuestaPost = await axios.post(API_ASISTENCIA['postasistencia'](tipoFecha, fechaSelec, horaSelec), datosAsistencia);
      console.log('Se subieron los datos correctamente:', respuestaPost.data);
      //La consulta es exitosa , se confirma el post.
      return true; // Devuelve true si el registro fue exitoso

    } catch (error) {
      //Si no es exitosa , se muestra un error
      setErrorMessage(error500, "(pi011e_a)");
      setShowToast(true);
      setShowModal(false);
      guardarLocalmente(datosAsistencia);
      return false; // Devuelve false si el registro fue exitoso

    }
  }
  const handleBLoqueRegister = async (tipoAsistencia) => {
    const dni = Cookies.get("dnisereno");
    const result = await Swal.fire({
      icon: 'question',
      title: tipoAsistencia === "E" ? "¿Está seguro de registrar los siguientes datos de entrada?" : "¿Está seguro de registrar los siguientes datos de Salida?",
      html:
        `
        <h5><strong>${datos.SERE_chNOM} ${datos.SERE_chAPEPAT} ${datos.SERE_chAPEMAT}</strong></h2>
        <p>DNI: <strong>${dni}</strong></p>
        <p>Función:<strong> ${selectedFuncion}</strong></p>
         <p>Codigo de Radio: ${selectedRecurso}</p>     
         <p>Codigo de Movil: ${selectedRecursoMovil}</p>    
         <p>Codigo de Puesto Fijo: ${selectedRecursoPF}</p>     
      `,
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar'
    });
    if (!longitud && !latitud) {
      Swal.fire({
        icon: "error",
        title: "Active su ubicación",
        text: "Active su ubicación y vuelva a cargar la página.",
      });
      return;
    }
    if (result.isConfirmed) {
      try {
        const resultados = await Promise.all([
          registroRecursoRadio(tipoAsistencia),
          registroAsistenciaBloque(tipoAsistencia),
          registroRecursoPuesto(tipoAsistencia),
          selectedRecursoMovil ? registroRecursoMovil(tipoAsistencia) : Promise.resolve(true)
        ]);

        const [confirmacionRadio, confirmacionAB, confirmacionPF, confirmacionMovil] = resultados;

        if (!confirmacionRadio || !confirmacionAB || !confirmacionPF || (selectedRecursoMovil && !confirmacionMovil)) {
          Swal.fire({
            icon: 'error',
            title: 'Error al registrar la asistencia',
            text: 'Debe Completar todos los campos',
          });
          setShowModal(false);
        } else {
          Swal.fire({
            icon: "success",
            title: "REGISTRO EXITOSO",
            text: "¡REGISTRE SU FOTO!",
            allowOutsideClick: false
          }).then(() => {
            setErrorMessage(`La asistencia se registrò correctamente`);
            setTimeout(() => {
              setErrorMessage('Ahora debe registrar su foto');
              setShowToast(true);
            }, 2000);
            setShowModal(false);
            setSelectedFuncion("")
            settRegistro("")
            setDesact("")
            setHoraSelec("")
            setFechaSelect("")
            setSelectedFuncion("")
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un error al registrar la asistencia.',
        });
        setShowModal(false);
      }
    } else {
      setErrorMessage(`El usuario canceló el registro`);
      setShowToast(true);
    }
  };

  useEffect(() => {
    const updateHoraActual = () => {
      const currentHora = new Date().toLocaleTimeString();
      setHoraActual(currentHora);
    };
    const intervalId = setInterval(updateHoraActual, 1000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const obtenerUbicacion = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatitud(latitude);
            setLongitud(longitude);
            console.log("la ubicacion es")
          },
          (error) => {
            setErrorMessage(`${error500} para obtener la ubicación`, error);
            setShowToast(true);
          }
        );
      } else {
        console.error("La geolocalización no es compatible con este navegador.");
      }
    };
    const verificarConexion = async () => {
      try {
        const response = await fetch('https://jsonplaceholder.typicode.com/posts/1', { method: 'HEAD' });

        if (response.ok) {
          console.log('Hay conexión a Internet.');
          enviarDatosPendientes();
          return true;
        } else {
          console.log('No hay conexión a Internet.');
          return false;
        }
      } catch (error) {
        console.log('No hay conexión a Internet.');
        return false;
      }
    };
    const fetchFunciones = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['funciones']);
        setFunciones(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener las funciones (api50g_par)`);
        setShowToast(true);
      }
    };
    const fetchRadios = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['radios']);
        setRadios(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener las radios (pi40frad_c)`);
        setShowToast(true);
      }
    };
    const fetchCamionetas = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['camionetas']);
        setCamionetas(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener las camionetas (pi40frad_c)`);
        setShowToast(true);
      }
    };
    const fetchMotos = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['motos']);
        setMotos(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener las motos (pi40fmotos)`);
        setShowToast(true);
      }
    };
    const fetchPuestosFijos = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['puestosFijos']);
        setPuestosFijos(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener los puestos tácticos (pi40fpuest)`);
        setShowToast(true);
      }
    };
    const fetchBicicletas = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['bicicletas']);
        setBicicletas(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener las bicicletas (pi40fbicic)`);
        setShowToast(true);
      }
    };
    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['usuarios']);
        setSerenos(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener los usuarios (pi120_M_MA)`);
        setShowToast(true);
      }
    };
    const fetchTRegistros = async () => {
      try {
        const response = await axios.get(API_ASISTENCIA['tipoRegistros']);
        setListTRegistros(response.data);
      } catch (error) {
        setErrorMessage(`${error500} para obtener los Tipos de Registros (api50i_par)`);
        setShowToast(true);
      }
    };
    fetchTRegistros()
    obtenerUbicacion()
    verificarConexion()
    fetchFunciones();
    fetchRadios();
    fetchCamionetas();
    fetchMotos();
    fetchPuestosFijos();
    fetchBicicletas();
    fetchUsuarios();
  }, []);

  const gettiporegistro = (tiporegistro) => {
    switch (tiporegistro) {
      case 'Asis':
        return 'Asistencia';
      case 'Inasis':
        return 'Inasistencia';
      case 'Acti':
        return 'Actividad';
      case 'Rad':
        return 'Radio';
      case 'Mot':
        return 'Moto';
      case 'Camio':
        return 'Camioneta';
      case 'Bic':
        return 'Bicicleta';
      case 'SSD':
        return 'Sueldo Sin Deposito';
      case 'SUP':
        return 'Sueldo Pagado';
      case 'PF':
        return 'Puesto Tactico';
      case 'PT':
        return 'Puesto Tactico';
      case 'Onom':
        return 'Onomástico';
      case 'PermP':
        return 'Permiso Personal';
      case 'PermS':
        return 'Permiso por Salud';
      case 'Vac':
        return 'Vacaciones';
      case 'De':
        return 'Descanso';
      default:
        return '';
    }
  };

  const handleLogout = () => {
    Cookies.remove("rol");
    Cookies.remove("nombre");
    Cookies.remove("apellidopat");
    Cookies.remove("apellidomat");
    Cookies.remove("dnilogin");
    Cookies.remove("miip");
    Cookies.remove("codigorol");
    Cookies.remove("idrol");
    Cookies.remove("horario");
    window.location.replace('/');
  };

  return (
    <div className="app">
      <h3 className="horaactual" style={{ fontWeight: '500', color: 'rgb(162, 162, 162)', fontSize: '15px', marginTop: '10px' }}>{fechahoy}</h3>
      <h3 className="horaactual" style={{ fontWeight: '500', color: 'rgb(162, 162, 162)', fontSize: '15px' }}>{horaActual}</h3>
      <header style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-50px' }}>
        <img src={require("../../componentes/logoerlan.png")} style={{ width: '15%', height: '80px', objectFit: 'cover' }} className='logoerlan' />
      </header>
      <nav >
        <a className="atras" href="/principal" style={{ cursor: 'pointer' }}><i className="fa-solid fa-arrow-left"></i> Atras</a>
        <a className="salir" onClick={handleLogout} style={{ cursor: 'pointer' }}><i className="fa-solid fa-users-slash"></i> Cerrar Sesión</a>
      </nav>
      <div className="app-container">
      <h1 className="tituloregistro"><i className="fa-solid fa-clipboard-check"></i> Registro de Control Detallado</h1>

        <div style={{ textAlign: 'left', width: '100%' }}>
          <p className="datos-usuarioizq" style={{ textTransform: 'capitalize', fontSize: '15px', color: 'rgb(68, 68, 69)' }}>Usuario: {nombre} {apellidopat} {apellidomat} - {dnilogin} - {horario ? horario : "No tiene un rol asignado"}
          </p>
          <p className="datos-usuarioizq" style={{ fontSize: '15px', marginTop: '-8px', color: 'rgb(68, 68, 69)' }}>Roles: {codigoderol} - {codigoderol2} - {codigoderol3}
          </p>
        </div>

        {showToast && (
          <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
            <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header bg-primary text-white">
                <strong className="me-auto">Mensaje del Sistema</strong>
                <small>{horaActual}</small>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowToast(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="toast-body">
                {errorMessage}
              </div>
            </div>
          </div>
        )}
        <div
          className="row" style={{width:'100%',height:'100%', justifyContent:'center',alignItems:'center'}}
        >
          <div className="col-lg-6 col-xl-6 col-sm-6" style={{display:"flex",flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
            <p  className='titulo-RegistroDoc' style={{ width:'100%', textAlign:'center',fontWeight:'bold',color:'#025E73'}}>Búsqueda por Documento {' '} <i className="fa-solid fa-id-card"></i></p>
            <div className="inputboton" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', width: '100%' }}>
              <input
                type="text"
                id="placaInput"
                value={documento}
                className="form-control"
                onChange={handleDocumentoChange}
                style={{ border: '1px solid rgb(22, 73, 200)', textAlign: 'center', width: '50%', fontSize: '15px', borderRadius: '2px' }}
                placeholder="Ingrese un DNI"
              />
              <button className="btn btn-success botonregistrar"
                type="button"
                onClick={obtenerDatos}
                disabled={!documento}
                style={{ width: '50%', fontSize: '15px', borderRadius: '2px' }}>
                Registrar {' '}
                <i className="fa-solid fa-pencil"></i>
              </button>
            </div>
            <div className="botonesabajo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '10px', width: '100%' }}>
              <button
                className="btn btn-success botonlistarnombres"
                type="button"
                onClick={handleShowBuscador}
                title="Buscar Usuario por Nombre"
                style={{ width: '50%', fontSize: '15px', borderRadius: '2px' }}
                disabled={rol === "01-USUARIO" || rol === "02-USUARIO_REG"}
              >
                Listar{' '}
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
              <div className="dropdown" style={{ width: '50%' }}>
                <button className="btn btn-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ width: '100%', fontSize: '15px', borderRadius: '2px' }}>
                  Ver Registros
                </button>
                <ul className="dropdown-menu" style={{ width: '100%', fontSize: '12px', borderRadius: '2px' }}>
                  <li>
                    <button
                      type="button"
                      className="btn btn-danger m-1 registrosusuario"
                      style={{ width: '95%', fontSize: '12px', borderRadius: '2px' }}
                      onClick={(e) => {
                        setshowModalTablaUsuario(true);
                      }}
                      disabled={!documento || (rol === "01-USUARIO" || rol === "02-USUARIO_REG")}
                    >
                      <strong>Registro Usuario</strong>{' '}
                      <i className="fa-solid fa-person-circle-question"></i>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn btn-success m-1 btnmisregistros"
                      style={{ width: '95%', fontSize: '12px', borderRadius: '2px' }}
                      onClick={(e) => {
                        setShowModalTablaMisRegistros(true);
                      }}
                    >
                      <strong>Mis Registros</strong>{' '}
                      <i className="fa-solid fa-address-book"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>


            <ModalAsistencia
          showModal={showModal}
          handleClose={handleClose}
          datos={datos}
          fechahoy={fechahoy}
          horaActual={horaActual}
          obtenerDatos={obtenerDatos}
          tipoRegistro={tipoRegistro}
          handleTipoRegistroChange={handleTipoRegistroChange}
          tipoFecha={tipoFecha}
          handleTipoFechaChange={handleTipoFechaChange}
          funciones={funciones}
          selectedFuncion={selectedFuncion}
          setSelectedFuncion={setSelectedFuncion}
          listTRegistros={listTRegistros}
          tregistro={tregistro}
          settRegistro={settRegistro}
          desact={desact}
          setDesact={setDesact}
          radios={radios}
          selectedRecurso={selectedRecurso}
          setSelectedRecurso={setSelectedRecurso}
          camionetas={camionetas}
          motos={motos}
          bicicletas={bicicletas}
          puestosFijos={puestosFijos}
          selectedRecursoMovil={selectedRecursoMovil}
          setSelectedRecursoMovil={setSelectedRecursoMovil}
          selectedRecursoPF={selectedRecursoPF}
          setSelectedRecursoPF={setSelectedRecursoPF}
          setSelectedCataCHSecpolicial={setSelectedCataCHSecpolicial}
          setSelectedCataCHJurpolicial={setSelectedCataCHJurpolicial}
          registrarAsistencia={registrarAsistencia}
          handleBLoqueRegister={handleBLoqueRegister}
          codigoUsuario={codigoUsuario}
          rolUsuario={rolUsuario}
          rolusuarionorol={rolusuarionorol}
          setRolUsiarioNoRol={setRolUsiarioNoRol}
          perfilesmes={perfilesmes}
          horaConsulta={horaConsulta}
          fechaSelec={fechaSelec}
          setFechaSelect={setFechaSelect}
          horaSelec={horaSelec}
          setHoraSelec={setHoraSelec}
        />

        <Modal show={showModalBuscador} onHide={handleClose} centered>
          <Modal.Header closeButton className="bg-success text-center">
            <Modal.Title style={{ "color": "#fff" }}>Buscar por Apellidos y Nombres </Modal.Title>
          </Modal.Header>
          <Modal.Body className="tabladedatos">
            <div className="mb-3">
              <label className="form-label">Nombre</label>
              <Select
                className="w-100 SelectRecurso"
                options={serenos.map(sereno => ({ value: sereno.SERE_P_chDNI, label: `( ${sereno.SERE_P_chDNI} ) ${sereno.SERE_APELLNOM}` }))}
                onChange={(selectedOption) => setDocumento(selectedOption ? selectedOption.value : null)}
                isSearchable
                placeholder="Buscar Personal"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="success" onClick={obtenerDatos} disabled={!documento}>
              Registrar
            </Button>
            <Button variant="warning" onClick={() => setshowModalTablaUsuario(true)} disabled={!documento}>
              Ver registros
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
          </div>
          <div className="col-lg-6 col-xl-6 col-sm-6">
          <QrCamera
        documentousuario={documento}
        documentoresponsable={dnilogin}
        dnivalido={dnivalido}
        idAsistencia={idAsistencia}
        handleResetIdAsistencia={handleResetIdAsistencia}
      />

          </div>
        </div>

      </div>

      <MisRegistros
        showModalTabla={showModalTablaMisRegistros}
        onClose={() => setShowModalTablaMisRegistros(false)}
        documento={dnilogin}
      />
      <RegistrosUsuario
        showModalTablaMes={showModalTablaUsuario}
        onClose={() => setshowModalTablaUsuario(false)}
        documento={documento}
      />
    </div>
  );
}
export default Registroasistencia;