// AsistenciaModal.js
import Cookies from 'js-cookie';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';


const ModalAsistencia = ({
  showModal,
  handleClose,
  datos,
  fechahoy,
  tipoRegistro,
  handleTipoRegistroChange,
  tipoFecha,
  handleTipoFechaChange,
  funciones,
  selectedFuncion,
  setSelectedFuncion,
  listTRegistros,
  tregistro,
  settRegistro,
  desact,
  setDesact,
  radios,
  selectedRecurso,
  setSelectedRecurso,
  camionetas,
  motos,
  bicicletas,
  puestosFijos,
  selectedRecursoMovil,
  setSelectedRecursoMovil,
  selectedRecursoPF,
  setSelectedRecursoPF,
  setSelectedCataCHSecpolicial,
  setSelectedCataCHJurpolicial,
  registrarAsistencia,
  handleBLoqueRegister,
  codigoUsuario,
  rolUsuario,
  rolusuarionorol,
  setRolUsiarioNoRol,
  perfilesmes,
  horaConsulta,
  fechaSelec,
  setFechaSelect,
  horaSelec,
  setHoraSelec
}) => {
  const rol = Cookies.get("rol")

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      setFechaSelect(selectedDate);
    }
  };

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    if (selectedTime) {
      setHoraSelec(selectedTime);
    }
  };


  return (
    <Modal show={showModal} onHide={handleClose} className="custom-modal" size={'lg'} centered>
      <Modal.Header closeButton className="text-center" style={{ backgroundColor: '#025E73' }}>
        <Modal.Title className="titulomodalregistro" style={{ "color": "#fff" }}>Registrar Entrada / Asistencia de personal </Modal.Title>
      </Modal.Header>
      <div className="btn-group radiobutonstr" role="group" aria-label="Basic radio toggle button group" style={{ padding: '10px' }}>
        <input
          type="radio"
          className="btn-check"
          name="options"
          id="option1"
          autoComplete="off"
          checked={tipoRegistro === 'REB'}
          onChange={() => handleTipoRegistroChange('REB')}
        />
        <label className={`btn ${tipoRegistro === 'REB' ? 'btn-success' : 'btn-secondary'}`} htmlFor="option1">
          Registro en Bloque
        </label>
        <input
          type="radio"
          className="btn-check"
          name="options"
          id="option2"
          autoComplete="off"
          checked={tipoRegistro === 'REI'}
          onChange={() => handleTipoRegistroChange('REI')}
        />
        <label className={`btn ${tipoRegistro === 'REI' ? 'btn-warning' : 'btn-secondary'}`} htmlFor="option2">
          Registro Individual
        </label>
      </div>
      {tipoRegistro === 'REI' ? <div class="alert alert-warning text-center alertafuncionselec" role="alert" style={{ fontSize: '18px', fontWeight: 'bold' }}>¡Estás registrando una asistencia Individual!</div> : <div class="alert alert-success text-center alertafuncionselec" role="alert" style={{ fontSize: '18px', fontWeight: 'bold' }}>¡Estás registrando una asistencia en Bloque!</div>}
      {tipoRegistro === 'REI' ?
        <Modal.Body className="datos">
          {datos && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '5px' }}>
                <p className="nombresapellidosmodal">Nombres: <strong >{datos.SERE_chNOM} {datos.SERE_chAPEPAT} {datos.SERE_chAPEMAT}</strong></p>
                <p className="nombresapellidosmodal">DNI: <strong>{datos.SERE_P_chDNI}</strong></p>
              </div>
              <div className="row">
                {codigoUsuario ?
                  <div className="col-xl-6 col-md-6 col-sm-6" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <p className="nombresapellidosmodal">Codigo de Rol:<br className="brdatos" /> <strong >{codigoUsuario}</strong></p>
                    <p className="nombresapellidosmodal">Rol de Usuario: <br className="brdatos" /><strong>{rolUsuario}</strong></p>
                  </div>
                  :
                  <div className="col-xl-6 col-md-6 col-sm-6" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <p class="alert alert-danger text-center" role="alert" style={{ fontSize: '12px' }}><strong> EL USUARIO NO TIENE UN PERFIL ASIGNADO</strong>
                      <Select
                        className=" selectPerfil"
                        options={perfilesmes.map(perfilesmes => ({ value: perfilesmes.cata_CH_Cod_rolserv, label: perfilesmes.cata_CH_Nombre_rolserv }))}
                        onChange={(selectedOption) => setRolUsiarioNoRol(selectedOption ? selectedOption.value : null)}
                        isSearchable
                        placeholder="Selecciona un Perfil"
                      />
                    </p>
                  </div>
                }
                <div className="col-xl-6 col-md-6 col-sm-6" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <p class="alert alert-primary text-center" role="alert" style={{ fontSize: '12px', width: '100%' }}>
                    <div className="btn-group radiobutonstr" role="group" aria-label="Basic radio toggle button group" style={{ width: '100%' }}>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                        id="option3"
                        autoComplete="off"
                        checked={tipoFecha === 'fechoy'}
                        onChange={() => handleTipoFechaChange('fechoy')}
                      />
                      <label className={`btn ${tipoFecha === 'fechoy' ? 'btn-danger' : 'btn-secondary'}`} htmlFor="option3" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        Registrar Hoy
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                        id="option4"
                        autoComplete="off"
                        checked={tipoFecha === 'fecmod'}
                        onChange={() => handleTipoFechaChange('fecmod')}
                        disabled={rol=== "01-USUARIO" || rol=== "02-USUARIO_REG"|| rol === "03-SUPERVISOR-AXD"}
                      />
                      <label className={`btn ${tipoFecha === 'fecmod' ? 'btn-primary' : 'btn-secondary'}`} htmlFor="option4" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        Registro Manual
                      </label>
                    </div>
                    <br />
                    {tipoFecha === 'fechoy' ?
                      <input
                        className="form-control"
                        type="text"
                        disabled
                         value={fechahoy}
                        style={{ margin: '5px', textAlign: 'center', fontSize: '15px', fontWeight: 'bold' }}

                      />
                      :
                      <div className="row">
                        <div className="col-xl-6 col-md-6 col-sm-12" style={{ display: 'flex', width: '50%', color: 'black' }}>
                          <input
                            className="form-control"
                            type="date"
                            style={{ margin: '5px 0', textAlign: 'center', fontSize: '12px', color: 'black', WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none' }}
                            onChange={handleDateChange} />
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-12" style={{ display: 'flex', width: '50%', color: 'black' }}>
                          <input
                            className="form-control"
                            type="time"
                            style={{ margin: '5px 0', textAlign: 'center', fontSize: '12px', width: '100%', color: 'black', WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none' }}
                            onChange={handleTimeChange}
                            onFocus={(e) => e.target.value = horaSelec || '08:00'}
                            onBlur={(e) => !horaSelec && (e.target.value = '')} />
                        </div>
                      </div>
                    }
                  </p>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ marginRight: '20px' }} className="size12RV">Función:</p>
                  <Select
                    className="w-100 SelectFuncion"
                    options={funciones.map(funcion => ({ value: funcion.per_CH_nomParam, label: funcion.per_CH_nomParam }))}
                    onChange={(selectedOption) => setSelectedFuncion(selectedOption ? selectedOption.value : null)}
                    isSearchable
                    placeholder="Selecciona una Función"
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom:'10px' }}>
                  <p style={{ width: '18%' }} className="tiporegistromodal"> Tipo Registro: </p>
                  <Select
                    className="w-100 SelectTR"
                    options={listTRegistros.map(tregistros => ({ value: tregistros.param_CH_codcatParam, label: tregistros.param_CH_nomParam }))}
                    onChange={(selectedOption) => settRegistro(selectedOption ? selectedOption.value : null)}
                    isSearchable
                    placeholder="Selecciona un Tipo de Registro"
                  />
                </div>

                {tregistro === "Inasis" || tregistro === "Onom" || tregistro === "PermP" || tregistro === "PermS" || tregistro === "Vac" || tregistro === "SSD" || tregistro === "Acti" || tregistro === "Tar" ?
                  <textarea
                    value={desact}
                    onChange={(e) => setDesact(e.target.value)}
                    style={{ width: '100%' }}></textarea>
                  : tregistro === "Rad" ?
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ marginRight: '20px', fontSize: '15px', width: '14%' }} className="size12RV">Radios:</p>
                      <Select
                        className="w-100  SelectRecurso"
                        options={radios.map(radio => ({ value: radio.cata_CH_Nomcatarec, label: radio.cata_CH_Nomcatarec }))}
                        onChange={(selectedOption) => setSelectedRecurso(selectedOption ? selectedOption.value : null)}
                        isSearchable
                        placeholder="Selecciona una Radio"
                      />
                    </div>
                    : tregistro === "Camio" ?
                      <div style={{ display: 'flex', alignItems: 'center' }} >
                        <p style={{ marginRight: '20px', fontSize: '15px', width: '14%' }} className="size12RV">Camioneta:</p>
                        <Select
                          className="w-100 SelectRecurso"
                          options={camionetas.map(camioneta => ({
                            value: camioneta.Cata_CH_Codcatarec, label: `${camioneta.Cata_CH_Codcatarec} - ${camioneta.cata_CH_Nomcatarec}`
                          }))}
                          onChange={(selectedOption) => setSelectedRecurso(selectedOption ? selectedOption.value : null)}
                          isSearchable
                          placeholder="Selecciona una Camioneta"
                        />
                      </div>
                      : tregistro === "Mot" ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ marginRight: '20px', fontSize: '15px', width: '14%' }} className="size12RV">Motos:</p>
                          <Select
                            className="w-100  SelectRecurso"
                            options={motos.map(moto => ({
                              value: moto.Cata_CH_Codcatarec, label: `${moto.Cata_CH_Codcatarec} - ${moto.cata_CH_Nomcatarec}`
                            }))}
                            onChange={(selectedOption) => setSelectedRecurso(selectedOption ? selectedOption.value : null)}
                            isSearchable
                            placeholder="Selecciona una Moto"
                          />
                        </div>
                        : tregistro === "Bic" ?
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ marginRight: '20px', fontSize: '15px', width: '14%' }} className="size12RV">Bicicletas:</p>
                            <Select
                              className="w-100 SelectRecurso"
                              options={bicicletas.map(bicicleta => ({
                                value: bicicleta.Cata_CH_Codcatarec, label: `${bicicleta.Cata_CH_Codcatarec} - ${bicicleta.cata_CH_Nomcatarec}`
                              }))}
                              onChange={(selectedOption) => setSelectedRecurso(selectedOption ? selectedOption.value : null)}
                              isSearchable
                              placeholder="Selecciona una Bicicleta"
                            />
                          </div>
                          : tregistro === "PT" ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <p style={{ marginRight: '20px', fontSize: '15px', width: '14%' }} className="size12RV">Puesto Tactico:</p>
                              <Select
                                className="w-100 SelectRecurso"
                                options={puestosFijos.map(puestofijo => ({
                                  value: puestofijo.Cata_CH_Codcatarec, label: `${puestofijo.Cata_CH_Codcatarec} - ${puestofijo.cata_CH_Nomcatarec}`
                                }))}
                                onChange={(selectedOption) => setSelectedRecurso(selectedOption ? selectedOption.value : null)}
                                isSearchable
                                placeholder="Selecciona un Puesto Fijo"
                              />
                            </div>
                            : ""
                }
                <div style={{ justifyContent: 'center', width: '100%', display: 'flex', marginTop: '10px' }}>
                  <p >Hora: <strong className="fechayhora">{horaConsulta}</strong></p>
                </div>
              </div>
              {tregistro === "Rad" || tregistro === "Mot" || tregistro === "Camio" || tregistro === "Bic" || tregistro === 'PT' ?
                <div className="botonesmodal">
                  <Button
                    className="botonentrada"
                    onClick={() => {
                      registrarAsistencia("R");
                    }}
                    disabled={!selectedFuncion || (tipoFecha === "fecmod" && !fechaSelec) || (!codigoUsuario ? !rolusuarionorol || rolusuarionorol === "" : null)}
                  >
                    Registrar Asistencia
                  </Button>
                  <Button className="botoncerrar" onClick={handleClose}>
                    Cerrar
                  </Button>
                </div>
                : tregistro === "SSD" || tregistro === "SUP" ?
                  <div className="botonesmodal">
                    <Button
                      className="botonentrada"
                      onClick={() => {
                        registrarAsistencia("P");
                      }}
                      disabled={!selectedFuncion || (tipoFecha === "fecmod" && !fechaSelec) || (!codigoUsuario ? !rolusuarionorol || rolusuarionorol === "" : null)}
                    >
                      Registrar
                    </Button>
                    <Button className="botoncerrar" onClick={handleClose}>
                      Cerrar
                    </Button>
                  </div>
                  : tregistro === "Onom" || tregistro === "PermP" || tregistro === "PermS" || tregistro === "Vac" || tregistro === "Acti" || tregistro === "Inasis" || tregistro === "Tar" || tregistro === "De" ?
                    <div className="botonesmodal">
                      <Button
                        variant="success"
                        className="botonentrada"
                        onClick={() => {
                          registrarAsistencia("O");
                        }}
                        disabled={!selectedFuncion || (tipoFecha === "fecmod" && !fechaSelec) || (!codigoUsuario ? !rolusuarionorol || rolusuarionorol === "" : null)}
                      >
                        Registrar
                      </Button>
                      <Button className="botoncerrar" variant="danger" onClick={handleClose}>
                        Cerrar
                      </Button>
                    </div>
                    :
                    <div className="botonesmodal">
                      <Button
                        variant="success"
                        className="botonentrada"
                        onClick={() => {
                          registrarAsistencia("E");
                        }}
                        disabled={!selectedFuncion || (tipoFecha === "fecmod" && !fechaSelec) || (!codigoUsuario ? !rolusuarionorol || rolusuarionorol === "" : null)}
                      >
                        Registrar Entrada
                      </Button>
                      <Button
                        variant="warning"
                        className="botonsalida"
                        onClick={() => {
                          registrarAsistencia("S");
                          
                        }}
                        disabled={!selectedFuncion || (tipoFecha === "fecmod" && !fechaSelec) || (!codigoUsuario ? !rolusuarionorol || rolusuarionorol === "" : null)}
                      >
                        Registrar Salida
                      </Button>
                      <Button className="botoncerrar" variant="danger" onClick={handleClose}>
                        Cerrar
                      </Button>
                    </div>
              }
            </div>
          )}
        </Modal.Body>
        :
        <Modal.Body className="datos">
          {datos && (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <p className="nombresapellidosmodal">Nombres: <strong >{datos.SERE_chNOM} {datos.SERE_chAPEPAT} {datos.SERE_chAPEMAT}</strong></p>
                <p className="nombresapellidosmodal">DNI: <strong>{datos.SERE_P_chDNI}</strong></p>
              </div>
              <div className="row">
                {codigoUsuario ?
                  <div className="col-xl-6 col-md-6 col-sm-6" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <p className="nombresapellidosmodal">Codigo de Rol:<br className="brdatos" /> <strong >{codigoUsuario}</strong></p>
                    <p className="nombresapellidosmodal">Rol de Usuario: <br className="brdatos" /><strong>{rolUsuario}</strong></p>
                  </div>
                  :
                  <div className="col-xl-6 col-md-6 col-sm-6" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <p class="alert alert-danger text-center" role="alert" style={{ fontSize: '12px' }}><strong> EL USUARIO NO TIENE UN PERFIL ASIGNADO</strong>
                    <Select
                        className="w-100 selectPerfil"
                        options={perfilesmes.map(perfilesmes => ({ value: perfilesmes.cata_CH_Cod_rolserv, label: perfilesmes.cata_CH_Nombre_rolserv }))}
                        value={rolusuarionorol ? { value: rolusuarionorol, label: rolusuarionorol } : null}
                        onChange={(selectedOption) => setRolUsiarioNoRol(selectedOption ? selectedOption.value : null)}
                        isSearchable
                        placeholder="Selecciona un Perfil"
                      />
                    </p>
                  </div>
                }
                <div className="col-xl-6 col-md-6 col-sm-6" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <p class="alert alert-primary text-center" role="alert" style={{ fontSize: '12px', width: '100%' }}>
                    <div className="btn-group radiobutonstr" role="group" aria-label="Basic radio toggle button group" style={{ width: '100%' }}>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                        id="option3"
                        autoComplete="off"
                        checked={tipoFecha === 'fechoy'}
                        onChange={() => handleTipoFechaChange('fechoy')}
                      />
                      <label className={`btn ${tipoFecha === 'fechoy' ? 'btn-danger' : 'btn-secondary'}`} htmlFor="option3" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        Registrar Hoy
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                        id="option4"
                        autoComplete="off"
                        checked={tipoFecha === 'fecmod'}
                        onChange={() => handleTipoFechaChange('fecmod')}
                        disabled={rol=== "01-USUARIO" || rol=== "02-USUARIO_REG" || rol === "03-SUPERVISOR-AXD"}
                      />
                      <label className={`btn ${tipoFecha === 'fecmod' ? 'btn-primary' : 'btn-secondary'}`} htmlFor="option4" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        Registro Manual
                      </label>
                    </div>
                    <br />
                    {tipoFecha === 'fechoy' ?
                      <input
                        className="form-control"
                        type="text"
                        disabled value={fechahoy}
                        style={{ margin: '5px', textAlign: 'center', fontSize: '15px', fontWeight: 'bold' }}

                      />
                      :
                      <div className="row">
                        <div className="col-xl-6 col-md-6 col-sm-6" style={{ display: 'flex', width: '50%' }}>
                          <input
                            className="form-control"
                            type="date"
                            style={{ margin: '5px 0', textAlign: 'center', fontSize: '12px' }}
                            onChange={handleDateChange} />
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-6" style={{ display: 'flex', width: '50%' }}>
                          <input
                            className="form-control"
                            type="time"
                            style={{ margin: '5px 0', textAlign: 'center', fontSize: '12px', width: '100%' }}
                            onChange={handleTimeChange}
                            onFocus={(e) => e.target.value = horaSelec || '08:00'}
                            onBlur={(e) => !horaSelec && (e.target.value = '')} />
                        </div>
                      </div>
                    }
                  </p>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center',marginBottom:'10px' }}>
                <p style={{ marginRight: '20px' }} className="size12RV">Función:</p>
                <Select
                  className="w-100 SelectFuncion"
                  options={funciones.map(funcion => ({ value: funcion.per_CH_nomParam, label: funcion.per_CH_nomParam }))}
                  onChange={(selectedOption) => setSelectedFuncion(selectedOption ? selectedOption.value : null)}
                  isSearchable
                  placeholder="Selecciona una Función"
                />
              </div>
              {selectedFuncion === 'SERENO MOTORIZADO' ?
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-lg-6">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ marginRight: '20px', fontSize: '15px', width: '14%' }} className="size12RV">Radios:</p>
                      <Select
                        className="w-100 SelectRadio"
                        options={radios.map(radio => ({ value: radio.cata_CH_Nomcatarec, label: radio.cata_CH_Nomcatarec }))}
                        onChange={(selectedOption) => setSelectedRecurso(selectedOption ? selectedOption.value : null)}
                        isSearchable
                        placeholder="Selecciona una Radio"
                      />

                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-lg-6">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ marginRight: '20px', fontSize: '15px', width: '14%' }} className="size12RV">Motos:</p>
                      <Select
                        className="w-100 SelectRecursoB"
                        options={motos.map(moto => ({
                          value: moto.Cata_CH_Codcatarec, label: `${moto.Cata_CH_Codcatarec} - ${moto.cata_CH_Nomcatarec}`
                        }))}
                        onChange={(selectedOption) => setSelectedRecursoMovil(selectedOption ? selectedOption.value : null)}
                        isSearchable
                        placeholder="Selecciona una Moto"
                      />
                    </div>
                  </div>
                </div>
                : selectedFuncion === 'SERENO CONDUCTOR' ?
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '20px', fontSize: '15px', width: '14%' }} className="size12RV">Radios:</p>
                        <Select
                          className="w-100 SelectRadio"
                          options={radios.map(radio => ({ value: radio.cata_CH_Nomcatarec, label: radio.cata_CH_Nomcatarec }))}
                          onChange={(selectedOption) => setSelectedRecurso(selectedOption ? selectedOption.value : null)}
                          isSearchable
                          placeholder="Selecciona una Radio"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '30px', fontSize: '15px', width: '14%' }} className="size12RV">Camioneta:</p>
                        <Select
                          className="w-100 SelectRecursoB"
                          options={camionetas.map(camioneta => ({
                            value: camioneta.Cata_CH_Codcatarec, label: `${camioneta.Cata_CH_Codcatarec} - ${camioneta.cata_CH_Nomcatarec}`
                          }))}
                          onChange={(selectedOption) => setSelectedRecursoMovil(selectedOption ? selectedOption.value : null)}
                          isSearchable
                          placeholder="Selecciona una Camioneta"
                        />
                      </div>
                    </div>
                  </div>
                  : selectedFuncion === 'OPERADOR DE CÁMARAS' || selectedFuncion === 'GRUPO GAR' || selectedFuncion === 'RADIO OPERADOR' || selectedFuncion === 'SERENO A PIE' || selectedFuncion === 'SERENO MUNICIPAL' || selectedFuncion === 'SERENO FISCALIZADOR' ?
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-lg-12 ">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ marginRight: '20px', fontSize: '15px', width: '14%' }} className="size12RV">Radios:</p>
                          <Select
                            className="w-100 SelectRadio"
                            options={radios.map(radio => ({ value: radio.cata_CH_Nomcatarec, label: radio.cata_CH_Nomcatarec }))}
                            onChange={(selectedOption) => setSelectedRecurso(selectedOption ? selectedOption.value : null)}
                            isSearchable
                            placeholder="Selecciona una Radio"
                          />
                        </div>
                      </div>
                    </div>
                    : ""
              }
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ marginRight: '20px', fontSize: '15px', width: '14%',marginTop:'10px' }} className="size12RV">Puestos Fijos:</p>
                <Select
                  className="w-100 SelectPF"
                  options={puestosFijos.map(puestofijo => ({
                    value: puestofijo.Cata_CH_Codcatarec,
                    label: `${puestofijo.Cata_CH_Codcatarec} - ${puestofijo.cata_CH_Nomcatarec}`,
                    jp: `${puestofijo.cata_CH_Jurpolicial}`,
                    sec: `${puestofijo.cata_CH_Secpolicial}`
                  }))}
                  onChange={(selectedOption) => {
                    setSelectedRecursoPF(selectedOption.value);
                    // Guarda los valores de Cata_CH_Secpolicial y Cata_CH_Jurpolicial cuando se seleccionan
                    setSelectedCataCHSecpolicial(selectedOption.sec);
                    setSelectedCataCHJurpolicial(selectedOption.jp);
                  }}
                  isSearchable
                  placeholder="Selecciona un Puesto Fijo"
                />
              </div>
              <div className="botonesmodal">
                <Button
                  className="botonentrada"
                  onClick={() => {
                    if (selectedRecursoPF) {
                      handleBLoqueRegister("R");
                    } else {
                      console.log("Please select a valid resource PT");
                    }
                  }}
                  disabled={!selectedFuncion || !selectedRecurso || !selectedRecursoPF || (tipoFecha === "fecmod" && !fechaSelec) || (!codigoUsuario ? !rolusuarionorol || rolusuarionorol === "" : null)}
                >
                  Registrar Entrada de Recurso
                </Button>
                <Button className="botoncerrar" onClick={handleClose}>
                  Cerrar
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>}
    </Modal>
  );
}

export default ModalAsistencia;